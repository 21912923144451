import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Button, Box, CircularProgress } from '@mui/material';
import { updateRent } from '../features/Item/actions';
import { formatDateDB } from '../features/util/formatters';
import { confirmPaymentIntent } from '../features/Payment/actions';

const ApproveRental = ({rh, refreshData}) => {
  // State to manage the selected value (either 'approve' or 'deny')
  const [selectedValue, setSelectedValue] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  // Function to handle radio button change
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true)
    const obj = {...rh}
    obj.approved = selectedValue
    obj.item_id = obj.item.id
    obj.itemImage_id = obj.itemImage.id
    obj.userProfile_id = obj.userProfile.id
    obj.dateApproved = formatDateDB(new Date())
    if(obj.approved){
      confirmPaymentIntent({'rentId':rh.id}).then(response =>{
        setIsLoading(false)
        refreshData()
      })
    }
    else{
      updateRent(rh.id, obj).then(response => {
        setIsLoading(false)
        refreshData()
      })
    }
  }

  return (
    <>
        {isLoading ?
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ color: 'black' }}/>
            </Box> 
        :
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="approval"
        name="approval"
        value={selectedValue}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="Approve"
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="Deny"
        />
      <Button 
        variant="contained"
        size = "small"
        onClick = {()=>{handleSubmit()}}
      >
        Submit
      </Button>
      </RadioGroup>

    </FormControl>
    }
    </>
  );
};

export default ApproveRental;