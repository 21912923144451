import { Grid, Typography } from '@mui/material';
import React from 'react';
import { mainHeadingColor } from '../../../theme';

export default function Faqs() {
    return (
        <Grid container ml ={1} mt={2}>
            <Grid item xs={12}>
                <Typography variant='h2' align="center">
                    FAQs
                </Typography> 
            </Grid>

            <Grid item xs={12} mt={2}>
            <Typography variant='h4' style={{ color: mainHeadingColor }}>
                What is Yelo Rentals?
            </Typography>
            </Grid>
            <Grid item xs={10} mt={1}>
            <Typography variant='h5'>
                Yelo Rentals is a Peer to Peer rental platform, allowing neighbours to borrow items with security and ease.
            </Typography>
            </Grid>
            
            <Grid item xs={12} mt={4}>
            <Typography variant='h4' style={{ color: mainHeadingColor }}>
                Are the items I post protected?
            </Typography>
            </Grid>
            <Grid item xs={10} mt={1}>
            <Typography variant='h5'>
                Yes, we take seriously our responsability of ensuring a safe return of your item or compensating you for loss or damages.
            </Typography>
            </Grid>
            
            <Grid item xs={12} mt={4}>
            <Typography variant='h4' style={{ color: mainHeadingColor }}>
                What is the cancellation policy?
            </Typography>
            </Grid>
            <Grid item xs={10} mt={1}>
            <Typography variant='h5'>
                All rentals can be cancelled up to 24 hours before date of pickup. 
            </Typography>
            </Grid>

            <Grid item xs={12} mt={4}>
            <Typography variant='h4' style={{ color: mainHeadingColor }}>
                Are there late fees?
            </Typography>
            </Grid>
            <Grid item xs={10} mt={1}>
            <Typography variant='h5'>
                Yes, renters will be charged the price per day of the item until returned or three days have passed. 
                After three days with no return, the rental will go into dispute and the renter may be charged the estimated value of the item. 
            </Typography>
            </Grid>
        </Grid>
    )
}