// routes
import Router from './routes';
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme'
import { CssBaseline } from '@mui/material';

// ----------------------------------------------------------------------

export default function App() {
  const Routing = Router()
  return (
    <ThemeProvider theme = {theme}>
      <CssBaseline/>
      {Routing}
    </ThemeProvider>
  );
}

