import React from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useEffect } from "react";
import { Grid, Typography, Card, CardContent, Chip, Divider } from '@mui/material';
import { useStyles } from '../styles';
import { formatDateNoTime } from "../features/util/formatters";
import ApproveRental from "./ApproveRental";

export default function HeaderCard(props) {

    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {

    }, [props])

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={10} md={10} mt = {2}>
                <Card className={classes.eggShell}>
                    <CardContent>
                        <Grid container>
                            <Grid item className={classes.miniBanner} xs={12} sm = {12} md = {12} style={{borderRadius: '10'}}>
                                <Typography variant ="title1" align="left">
                                    {props.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm = {12} md = {12} mb = {2}>
                                <Divider />
                            </Grid>
                            {props.return}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
