import { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyles } from '../../../styles';
import { Button, CircularProgress, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,  } from '@mui/material';
import { useParams } from "react-router-dom";
import { getRental } from '../../User/actions';
import { calculateTotalDays } from '../paymentHelper';
import { confirmPayment, createPaymentIntent, createStripeAccount } from '../actions';
import WelcomeBanner from '../../../components/WelcomeBanner';
import { useUser } from '../../User/userContext';


export default function BillingAccounts() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)


  const { user } = useUser();

  console.log(user)

  useEffect(() => {
    if(user){
        setIsLoading(false)
    }
  }, [user])

  const handleCreateStripeAccount = () => {
    setButtonLoading(true)
    const newTab = window.open('', '_blank');
    createStripeAccount().then(response => {
        setButtonLoading(false)
        const { url } = response
        if (newTab) {
          newTab.location.href = url;
        } else {
            // Handle the case where the new tab didn't open (e.g., pop-up blocked)
            alert('Unable to open new tab. Please check your pop-up blocker settings.');
        }
        // window.open(url, '_blank');

    })
  }

  return (
    <>
    {isLoading ? <CircularProgress/> :
    <Grid container spacing={3} justifyContent="center">
        <Grid item xs = {10} mt={2}>
            <WelcomeBanner banners={['Billing Accounts']}/>
        </Grid>
            
                <Grid container item xs = {10} mt={2}>
                    <Grid item>
                       We use internationally recognized Stripe Payments to process payments to our vendors. By clicking the button bellow we will share your first name, last name, and email with Stripe. 
                       <br/>
                       <br/>
                       Stripe will also ask for other information for their own records and for tax purposes. This includes your phone number, business website (if you dont have one please use yelorentals.com), and banking direct deposit information so your earnings can be transfered. 
                       If you have any questions please contact customer-support@yelorentals.com
                       <br/>
                       <br/>
                       After this you can get paid directly 24 hours after pickup!
                        <Grid item mt = {2}>
                        {
                        user.user.stripeConnectId ? 
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => window.location.href = `https://dashboard.stripe.com/connect/accounts/${user.user.stripeConnectId}`}
                                >
                                Open Stripe Dashboard
                            </Button>
                            :
                            <Button variant='contained' disabled = {buttonLoading} onClick = {() => {handleCreateStripeAccount()}}>
                                {buttonLoading ? 'Loading..' : 'Create Stripe Billing account.'} 
                            </Button>
                        }
                        </Grid>
                    </Grid>
                </Grid>
    </Grid>
  }
  </>
  );
}
