import React, { useState } from 'react';
import { Card, CardMedia, IconButton, makeStyles, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useStyles } from '../styles';

const ImageViewer = ({ images }) => {
  const classes = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <Card className={classes.imageRoot}>
    <div className={classes.imageCardContent}>
        <CardMedia
            className={classes.imageMedia}
            component="img"
            image={images[currentImageIndex]}
            title={`Image ${currentImageIndex + 1}`}
        />
      </div>
      <Typography variant="caption" color="textSecondary" align="center">
        {`Image ${currentImageIndex + 1} of ${images.length}`}
      </Typography>
      <div className={classes.imageNavigation}>
        <IconButton onClick={handlePrev} color="primary">
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={handleNext} color="primary">
          <ChevronRight />
        </IconButton>
      </div>
    </Card>
  );
};

export default ImageViewer;