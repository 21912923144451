import Resizer from 'react-image-file-resizer';


export const getImageFromUrl = async (url) => {
    if (url) {
      const data = await fetch(url)
      const blob = await data.blob()
      if (data.status === 404) {
        return false
      }
      const fileName = url.split("/").pop()

      const file = new File([blob], fileName, { type: blob.type });
      console.log(file)
      return file
    //   setSelectedImage(file)
    } else {
      return false
    }
  }

 export const handleImageUpload = (e, setPreviewImage, previewImage) => {
  
    const file = e.target.files[0];
    
    Resizer.imageFileResizer(
      file,
      250, // Specify the desired width (in pixels) for the resized image
      250, // Specify the desired height (in pixels) for the resized image
      'JPEG', // Specify the desired output format
      100, // Specify the desired image quality (0-100)
      0, // Specify the rotation angle (0-360) if required
      (resizedImage) => {
        const images = [...previewImage]
        images.push(resizedImage)
        setPreviewImage([...previewImage, resizedImage])
      },
      'base64' // Output type (base64 or blob)
    );
};

