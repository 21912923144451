import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { checkAuth } from './actions';

// Initial state
const initialState = {
  user: null,
  isAuthenticated: false,
  location: null,
};

// Action types
const SET_USER = 'SET_USER';
const LOGOUT = 'LOGOUT';
const SET_LOCATION = 'SET_LOCATION'

// Reducer function
const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload
      }
    default:
      return state;
  }
};

// Create context
const UserContext = createContext();

// Create provider component
const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const setUser = (user) => {
    dispatch({ type: SET_USER, payload: user });
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  const setLocation = (location) => {
    dispatch({type: SET_LOCATION, payload: location})
  }

  useEffect(() => {
    // Check authentication status on component mount
    checkAuth().then(response => {
        setUser({ ...response}); 
      })
      .catch(error => {
        console.log("HERE")
        console.error('Error checking authentication status:', error);
        // setUser({user:null, isAuthenticated: false}); 
      });
  }, []);

  return (
    <UserContext.Provider value={{ ...state, setUser, logout, setLocation }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
