import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkStripeAccount } from '../actions';

export default function WelcomeBack(){

    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        checkStripeAccount().then(response => {
            if(response.value === 'success'){
                navigate('/successpage/')
            }
            else{
                navigate('/redopage/')
            }
        })
    }, []);

    return (
        <>
        { isLoading ? 
            <CircularProgress/> : 
        <>
            <div>
                <h1>Account Setup Successful</h1>
                <p>Your Stripe Connect account has been successfully created.</p>
            </div>
            </>
        }
        </>
    );
};