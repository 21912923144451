import React, { useState } from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useEffect } from "react";
import { Button, Grid, Typography, Card, CardContent, CardMedia, Divider, IconButton, Tooltip, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mainComponentColor, mainHeadingColor } from "../theme";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteItem } from "../features/Item/actions";

export default function ItemCard({item, index, showEdit, showDelete, refreshBanner}) {

    const navigate = useNavigate();

    const [openDelete, setOpenDelete]=useState(false)
    const [errorDeleteMessage, setErrorDeleteMessage] = useState()

    const handleClick = () =>{
        const slug = item.name.replace(/\s+/g, '-');
        navigate(`../item/${slug}/${item.item_id}`)
    }

    useEffect(() => {
        
    }, [item])

    const onConfirmDelete = () => {
        deleteItem(item).then(response=>{
            setOpenDelete(false)
            refreshBanner()
        }).catch(error=>{
            setErrorDeleteMessage("Something went wrong")
        })
    }

    return (
            <Card style={{ borderRadius: '16px' }}>
                <CardContent>
                    <Grid item container justifyContent="flex-end">
                    {showEdit ?
                        <Tooltip title="Edit">
                            <IconButton onClick={() => {navigate(`/base/upload/${item.item_id}`)}} sx={{ color: mainComponentColor }}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>

                    : null}
                    {showDelete ? 
                    <Tooltip title="Delete">
                        <IconButton onClick={() => setOpenDelete(true)} sx={{ color: mainComponentColor }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                    : null}
                    <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} maxWidth="md" fullWidth>
                        <DialogTitle>Delete Item</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you would like to delete this item?
                            </DialogContentText>
                            <DialogContentText>
                                {item.name}
                            </DialogContentText>
                        </DialogContent>
                        
                        {errorDeleteMessage ? 
                        <DialogContent>
                            <DialogContentText>
                                {errorDeleteMessage}
                            </DialogContentText> 
                        </DialogContent>: null}
                        <DialogActions>
                            <Button onClick={() => onConfirmDelete()} sx={{ color: 'red' }}>
                                Delete Item
                            </Button>
                            <Button onClick={() => setOpenDelete(false)} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </Grid>
                    <div style={{ cursor: 'pointer' }} onClick = {() => {handleClick()}}>
                        <Grid container style = {{ height: '400px'}}>
                            <Grid item xs={12} sm = {12} md = {12} style={{ height: '300px', overflow: 'hidden' }}>
                                <CardMedia
                                    component="img"
                                    image={`${process.env.REACT_APP_BACKEND_URL}/media/${item.image}`}
                                    title={item.name}
                                    style={{ 
                                        height: '100%', 
                                        width: '100%', 
                                        objectFit: 'cover',  // Ensures the image covers the entire container
                                        objectPosition: 'center' // Centers the image
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm = {12} md = {12} style={{
                                    height: '45px', // Set your desired maximum height here
                                    overflow: 'hidden'  // This will hide any content that exceeds the maximum height
                                  }}>
                                <Typography variant ="itemCardHeader" align="left" title={item.name}>
                                    {item.name.length > 60 ? `${item.name.substring(0, 57)}...` : item.name}
                                </Typography >
                            </Grid>
                            <Grid item xs={12} sm ={12} md = {12} mt = {1}>
                                <Divider/>
                            </Grid>
                            <Grid container mt= {0.5}>
                                <Grid item xs={9}>
                                    ${item.pricePerDay} / day
                                </Grid>
                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{
                                        width: '25px', // Reduced size
                                        height: '25px', // Reduced size
                                        borderRadius: '50%',
                                        backgroundColor: mainHeadingColor,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        lineHeight: '20px',
                                        color:'white'
                                    }}>
                                        <Typography align="center" variant="smallDescriptor" >
                                        {item.rating ? item.rating : '5.0'}
                                        </Typography>
                                    </div>
                                                                
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant ="smallDescriptor" align="left" title={item.addressName}>
                                        {item.addressName}
                                    </Typography >
                                </Grid>
                                <Grid item xs={2} sm = {2} md = {2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Typography variant ="smallDescriptor" color="textSecondary" align="left" title={item.name}>
                                        {item.distance}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
    )
}
