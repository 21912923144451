import React from 'react';
import { useUser } from '../userContext';
import { Typography, Grid, Card, CardContent,Button, Divider, IconButton, TextField, InputAdornment, Avatar, Paper, CircularProgress } from "@mui/material";
import { useStyles } from '../../../styles';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { checkEmail, createUserProfile, sendVerificationCode, verifyCode } from '../actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { mainHeadingColor } from '../../../theme';
import { ImageUpload } from '../../../components/ImageUpload';

export default function CreateProfile(){
    const classes = useStyles()
    const navigate = useNavigate()

    const [userData, setUserData] = React.useState({})
    const [phase, setPhase] = React.useState(0)

    const [verificationCode, setVerificationCode] = React.useState()

    const [emailErrorMessage, setEmailErrorMessage]  = React.useState('')
    const [verificationErrorMessage, setVerificationErrorMessage] = React.useState('')

    const [loading, setLoading] = React.useState(false)

    const [profilePicture, setProfilePicture] = React.useState([]);


    const formatPhoneNumber = (value) => {
        const cleaned = value.replace(/[^\d]/g, '');
        if (cleaned.length <= 10) {
            const part1 = cleaned.slice(0, 3) ? `(${cleaned.slice(0, 3)}` : '';
            const part2 = cleaned.slice(3, 6) ? `) ${cleaned.slice(3, 6)}` : '';
            const part3 = cleaned.slice(6, 10) ? `-${cleaned.slice(6, 10)}` : '';
            const formattedNumber = `${part1}${part2}${part3}`;
            handleData('phoneNumber', formattedNumber);
        }
    };

    const handleData = (field, data) => {
        let obj = {...userData}
        obj[field] = data
        if(field === 'username'){
            obj[field] = data.toLowerCase()
            obj['email'] = data.toLowerCase()
        }
        setUserData(obj)
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('username', userData.username.toLowerCase());
        formData.append('email', userData.email.toLowerCase());
        formData.append('password', userData.password);
        formData.append('first_name', userData.first_name);
        formData.append('last_name', userData.last_name);
        formData.append('profilePicture', profilePicture && profilePicture.length !== 0 ?  profilePicture[0].file: null); // Assuming `profilePicture` is a File object
        formData.append('phoneNumber', userData.phoneNumber);
        setLoading(true)
        createUserProfile(formData).then(response=>{
            setLoading(false)
            navigate('/base/login/')
        })
    }

    const verifyEmail = () => {
        verifyCode(userData.username, verificationCode).then(response=> {
            console.log(response)
            setPhase(1)
        })
    }

    const handleSubmitEmail = ()=> {
        const email = userData.username.toLowerCase()
        const emailRegex = /\S+@\S+\.\S+/

        if(emailRegex.test(email)){
            
            checkEmail(email).then(response=>{
                if(response.length === 0){
                    sendVerificationCode(email).then(response2=> {
                        setPhase(0.5)
                        console.log(response2)
                    }).catch(response2=>{
                        setVerificationErrorMessage('Wrong code, please try again')
                    })
                }
                else{
                    setEmailErrorMessage('User already exists with that email')
                }
            })
        }
        else{
            setEmailErrorMessage('Please enter a valid email')
        }
    }
  
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={12} ms={12} lg={12} align="center">
                <Typography variant ="h2" >
                    Welcome to Yelo!
                </Typography>
            </Grid>
            { phase === 0 && (
            <Grid container spacing = {1} item xs={12} sm = {6} md = {6} mt = {5}>
                <Grid item xs={12}>
                    <Typography>Email Address</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField size="small" required fullWidth onChange={(e)=> handleData('username',e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <span style={{ color: 'red' }}>{emailErrorMessage}</span>
                </Grid>
                <Grid item xs={7}>
                    <Button variant = "contained" onClick={()=>{handleSubmitEmail()}}>continue</Button>
                </Grid>
            </Grid>
            )}
            { phase === 0.5 && (
                <Grid container spacing = {1} item xs={12} sm = {6} md = {6} mt = {5}>
                <Grid item xs={12}>
                    <Typography>We have sent a verification code to your email</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField size="small" required fullWidth label = 'Verification Code' onChange={(e)=> setVerificationCode(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <span style={{ color: 'red' }}>{verificationErrorMessage}</span>
                </Grid>
                <Grid item xs={7}>
                    <Button variant = "contained" onClick={()=>{verifyEmail()}}>continue</Button>
                </Grid>
            </Grid>
            )}
            { phase === 1 && (
                
                <Grid container spacing = {1} item xs={12} sm = {6} md = {6} mt = {5}>
                    <form onSubmit={handleSubmit}>
                    <Grid container spacing = {2} item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h1">New Profile</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Email Address</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" disabled value = {userData.email} required fullWidth onChange={(e)=> handleData('username',e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Password</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField type = "password" size="small" required onChange={(e)=> handleData('password',e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Confirm Password</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField type = "password" size="small" required onChange={(e)=> handleData('confirm_password',e.target.value)}/>
                        {userData.confirm_password && userData.password === userData.confirm_password ? <CheckCircleIcon style={{ color: mainHeadingColor }}/>: null}
                    </div>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h1">Personal Information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>First Name</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" required fullWidth onChange={(e)=> handleData('first_name',e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Last Name</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" required fullWidth onChange={(e)=> handleData('last_name',e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Phone Number</Typography> 
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small"  inputProps={{ inputMode: 'numeric' }} required value = {userData.phoneNumber} fullWidth onChange={(e)=> formatPhoneNumber(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Profile Picture (optional)</Typography> 
                    </Grid>
                    <Grid item xs={9} md ={9}>
                        <ImageUpload images={profilePicture} setImages={setProfilePicture} limit={1}/>
                    </Grid>

                    <Grid item xs={12} sm = {12} md = {12} mt={2}>
                        {loading ? <CircularProgress/> : <Button variant = "contained" type="submit">Create Profile</Button>}
                    </Grid>
                    </Grid>
                    </form>
                </Grid>

            )}
        </Grid>

    );
};

{/* <Grid container>
            <Grid item  xs={12} sm={12} ms={12} lg={12} align="center">
                <Typography variant ="h2">
                    Welcome to Yelo!
                </Typography>
            </Grid>
            <Grid container item xs ={12} sm={12} md ={12} lg ={12} ml = {8} align="center" style={{ border: '1px solid black' }}>
            <form onSubmit={handleSubmitEmail}>

                    {
                        phase === 0 && (
                            <>
                            <Grid item xs={12} sm = {12} md = {12} mt = {5}>
                                <Typography>Email</Typography>
                                <TextField size="small" fullWidth required onChange={(e)=> {handleData('username',e.target.value);handleData('email',e.target.value)}}/>
                            </Grid>
                            
                            <Grid item xs={12} sm = {12} md = {12} mt={2}>
                                <Button variant = "contained" type="submit">continue</Button>
                            </Grid>
                            </>
                        )
                    }

            </form>
                                </Grid>
            <form onSubmit={handleSubmit}>
                <Grid container item xs ={12} sm={12} md ={12} lg ={12} ml = {8}>
                    {phase !== 0 && (
                        <>
                        <Grid item xs={12} sm = {12} md = {12} mt={2}>
                            <Typography>Password</Typography>
                            <TextField type = "password" size="small" onChange={(e)=> handleData('password',e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm = {12} md = {12} mt={2}>
                            <Typography>Confirm Password</Typography>
                            <TextField type = "password" size="small" onChange={(e)=> handleData('confirm_password',e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm = {12} md = {12} mt={2}>
                            <Button variant = "contained" type="submit">continue</Button>
                        </Grid>
                        </>
                    )
                    }
                </Grid>
            </form>

        </Grid> */}