import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SuccessPage(){

    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(false)
    }, []);

    return (
        <>
        { isLoading ? 
            <CircularProgress/> : 
            <Grid container item xs={12} sm = {12} md = {12} spacing={2} mt={1} justifyContent="center" alignItems="center">
                <Grid item xs = {10}>
                    <Typography variant="h1" align="center">Account Setup Successful</Typography>
                </Grid>
                <Grid item xs = {10}>
                    <Typography variant='body1' align="center">Your Stripe Connect account has been successfully created. </Typography>
                </Grid>
                <Grid item xs = {3} justifyContent="center" display="flex">
                <Button variant = 'contained' onClick={()=>{navigate('/home/')}}>Return Home</Button>
                </Grid>
            </Grid>
        }
        </>
    );
};