import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearUserConnectId } from '../actions';
import { checkAuth } from '../../User/actions';
import { useUser } from '../../User/userContext'

export default function RedoPage(){

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { setUser } = useUser();

    useEffect(() => {
        clearUserConnectId().then(response => {
            checkAuth().then(response => {
                setUser({ ...response}); 
                navigate('/billingaccounts/')
            })
        })
    }, []);

    return (
        <>
        { isLoading ? 
            <CircularProgress/> : 
        <>
            <div>
                <h1>Account Setup Unsuccessful</h1>
                <p>Navigating to Billing account to Redo.</p>
            </div>
            </>
        }
        </>
    );
};