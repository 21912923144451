import { Grid } from '@mui/material';
import React from 'react';

export default function TermsAndCondition() {
    return (
        <Grid item ml={2}>
<h1>Terms and Conditions</h1>
<br/>
<h2>Introduction</h2>
<br/>
<p>
    Welcome to Yelo Rentals! These Terms and Conditions ("Terms") govern your use of the Yelo Rentals platform located at Yelorentals.com ("Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
</p>
<br/>
<h2>License</h2>
<p>Unless otherwise stated, Yelo Rentals and/or its licensors own all intellectual property rights in the materials on Yelo Rentals. You are granted a limited license to access the Service for personal use only, subject to the restrictions outlined herein. You must not:</p>
<ul>Republish material from Yelo Rentals.</ul>
<ul>Sell or sub-license any material or items rented from Yelo Rentals.</ul>
<ul>Reproduce, duplicate, or copy material from Yelo Rentals.</ul>
<ul>Redistribute content from Yelo Rentals.</ul>
<br/>
<h2>User Accounts</h2>
When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
<br/>
You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
<br/>
You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
<br/>
<h2>User Content</h2>
<br/>
In these terms and conditions, “Your User Content” shall mean any text, images, or other material you choose to display on this web app. By displaying Your User Content, you grant Yelo Rentals a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.

<br/>
Your User Content must be your own and must not be infringing on any third party’s rights. Yelo Rentals reserves the right to remove any of Your User Content from this web app at any time without notice.
<br/>

<h2>Privacy Policy</h2>

By signing up with Yelo, you acknowledge that you are sharing your name and email with Yelo rentals. This information will only be used for contacting you for updates regarding rental request, confirmation, changes, cancellations or dispute. We do not share your personal information with any third party.
<br/>
By signing up with Gmail, all your google data is held by google
<br/>
By using our payments you acknowledge you are using Stripes payment platform. Stripe is responsible for any confidential information shared with them. 
<br/>
You may not hold Yelo liable for any data provided to Google or Stripe.
<br/>

<h2>Listing and Renting Items</h2>

<h3>Listing Items</h3>

<ul>Users can list everyday items for rent by providing accurate descriptions, prices, and availability.</ul>
<ul>By listing an item, the user represents and warrants that they own or have the legal right to rent the item and that the item is in good condition.</ul>
<ul>Ensure that item descriptions are clear, accurate, and include relevant details such as condition, age, and any limitations.</ul>
<ul>For high priced goods, owner may have their own disclaimer and terms signed by the renter </ul>
<ul>Lender is responsible for accessing the item on return of rental. If the item is returned damaged beyond general wear and tear, disputes must be opened within 24 hours of return of said item.</ul>
<ul>Yelo rentals is not responsible for high priced good ($300 CAD)</ul>
<ul>Items considered illegal in your local territory must not be posted. This is grounds for cancellation of your account and permanent ban from Yelo’s platform.</ul>
<ul>Items considered controlled or controlled substances must not be posted. This is grounds for cancellation of your account and permanent ban from Yelo’s platform.</ul>
<ul>Yelo reserves the right to remove any items posted that do not align with our terms and conditions.</ul>

<h3>Renting Items</h3>

<ul>Users can browse and rent items listed on the platform.</ul>
<ul>Rental transactions, including the collection and disbursement of rental fees, are facilitated through Yelo Rentals.</ul>
<ul>Renter is responsible for accessing the item to be in working condition before rental. If the item is accepted it will be considered as working condition. </ul>
<ul>Yelo Rentals acts solely as an intermediary to facilitate transactions between users and is not a party to the rental agreements between users.</ul>
<ul>Damaged items will go into dispute. Yelo solely reserves the right to investigate and judge the disputes.  You may be held liable for damages. </ul>
<ul>We reserve the right to charge the renter for the damages in cases where damages are considered beyond general wear and tear.</ul>
<ul>Any items not returned by the reservation return date are liable for late charges. Late charges will be applied up to 3 days after the return date. Beyond the 3 days, the renter will be charged the replacement cost of the item/s. </ul>
<ul>In an event we are not able to chargeback the renter unreturned items, the renter will be reported under theft to authorities and a permanent ban from Yelo’s platform.</ul>

<h2>Rental Stores</h2>
<ul>Stores are responsible for getting their own disclaimer and terms and conditions signed during rental pickup</ul>
<ul>Stores may hold a security/damage deposit which shall be returned in full upon successful return of item in its original condition. </ul>
<ul>Any damage settlements are to be resolved between the store and the renter. </ul>
<ul>Stores opting to rent higher value items, you acknowledge the risks and may choose to enforce your own disclaimer and rental policies to protect your property. </ul>

<h2>Fees and Payments</h2>
<ul>Users must create and link a Stripe account to their Yelo account to receive payments.</ul>
<ul>The listing user sets the rental price for their items.</ul>
<ul>Yelo Rentals will charge a service fee for facilitating the rental transaction. This fee will be a percentage of the rental price and will be deducted from the payment made to the listing user.</ul>
<ul>Payments will be processed through Stripe, and the net rental income (after service fees) will be disbursed to the listing user after all applicable fees and taxes.</ul>
<ul>Stripe charges ($0.30CAD + 3%) fee to use their platform. </ul>
<ul>Yelo charges a 5% facilitation fee </ul>
<ul>Stripe and Yelo Fees will be deducted from the Lender and the remaining amount after deductions will be sent to Lenders stripe dashboard. </ul>
<ul>Local sales tax (such as GST/HST) will be charged to the Renter in addition to the posted amount. </ul>

<h2>Mediation and Disputes</h2>
Disputes must be reported within 24 hours of item return. Yelo Rentals reserves the right to request evidence, such as photographs. If mediation fails, Yelo will make a decision based on the evidence, which may involve refunds or damage claims. By agreeing to these Terms, you accept Yelo's right to resolve disputes and will not hold Yelo liable for damages.

<h2>Cancellations and Refunds</h2>
<ul>Users may cancel their rental reservations according to the cancellation policy.</ul>
<ul>Refunds will be processed according to the terms agreed upon at the time of the rental transaction.</ul>
<ul>If the item owner cancels the rental reservation, the amount will be fully refunded to the renter.</ul>
<ul>If the renter cancels the reservation, refund will be processed as stated in the cancellation policy. Service fees charged by Yelo Rentals are non-refundable.</ul>


<h2>User Responsibilities</h2>
<ul>Users are responsible for maintaining and returning the items they rent in the condition they were provided.</ul>
<ul>Users agree to return rented items on time and in the condition they received them, subject to reasonable wear and tear.</ul>
<ul>Users are liable for any damage, loss, or theft of rented items during the rental period.</ul>
<ul>Renter is responsible for safe use of any items rented off the platform. Yelo is not liable for any injuries or damages that may occur with the use or misuse of the rented item.</ul>
<ul>Harassment, discrimination, and illegal activities will not be tolerated and may result in a ban of your account.</ul>

<h2>Limitation of Liability</h2>
In no event shall Yelo Rentals, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this web app whether such liability is under contract. Yelo Rentals, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this web app or items rented off the app.
<h2>Force Majeure Clause</h2>
Neither party shall be liable for any delay or failure to perform its obligations under this Agreement if such delay or failure is caused by a force majeure event. A force majeure event shall mean any event beyond the reasonable control of the affected party, including but not limited to:
<ul>Natural disasters: Earthquakes, hurricanes, floods, wildfires, or other severe weather events as recognized by the Government of Canada or the Province of Ontario.</ul>
<ul>Acts of God: Events that cannot be reasonably foreseen or prevented, such as lightning strikes, tornadoes, or extreme weather conditions.</ul>
<ul>War or armed conflict: Including acts of terrorism, civil unrest, or hostilities between nations.</ul>
<ul>Government actions: Laws, regulations, or decrees issued by the Government of Canada or the Province of Ontario that make it impossible or impractical to perform the contract.</ul>
<ul>Epidemics or pandemics: Such as the COVID-19 pandemic or other widespread infectious diseases declared by public health authorities.</ul>
<ul>Strikes or labor disputes: Affecting essential services or industries relevant to the performance of this Agreement.</ul>
<ul>Infrastructure failures: Power outages, transportation disruptions, or other critical infrastructure failures within Ontario.</ul>
If a force majeure event occurs, the affected party shall provide prompt written notice to the other party and shall use reasonable efforts to mitigate the effects of the force majeure event. If the force majeure event continues for 30 days, either party may terminate this Agreement without liability.

<br/>

In case of a force majeure event any ongoing reservations are still expected to return the items on agreed date. In case of a force majeure event any future rentals may be canceled without being charged any cancellation fees. Yelo reserves the right to cancel future/pending rentals in case of any such emergency event. 


<h2>Indemnification</h2>
You hereby indemnify to the fullest extent Yelo Rentals from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.

<h2>Severability</h2>
If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.

<h2>Variation of Terms</h2>
Yelo Rentals is permitted to revise these Terms at any time as it sees fit, and by using this web app you are expected to review these Terms on a regular basis.

<h2>Assignment</h2>
Yelo Rentals is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.

<h2>Entire Agreement</h2>
These Terms constitute the entire agreement between Yelo Rentals and you in relation to your use of this web app and supersede all prior agreements and understandings.

<h2>Governing Law & Jurisdiction</h2>
These Terms will be governed by and interpreted in accordance with the laws of Province of Ontario, and you submit to the non-exclusive jurisdiction of the provincial and federal courts located in Ontario for the resolution of any disputes.
<h2>Amendments to Terms and Conditions</h2>
Yelo Rentals reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Users are encouraged to review the Terms regularly to stay informed of any modifications. Continued use of the Service after any changes constitute your acceptance of the revised Terms. If you do not agree to the new Terms, you must cease using the Service.
<h2>Acknowledgements</h2>
By signing up on our platform you accept our terms and conditions.

        </Grid>
);
}
