export const initAutocomplete = (callBack) => {
    const input = document.getElementById('autocompleteInput');
    if (!input) {
        console.error('Autocomplete input element not found');
        return;
    }
    const style = document.createElement('style');
    style.innerHTML = `
        .pac-container {
            width: 300px !important;
        }
    `;
    document.head.appendChild(style);
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        componentRestrictions: { country: 'ca' }
    });

    const torontoBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(43.371819, -79.724540), // Southwest corner of Toronto
        new window.google.maps.LatLng(44.745800, -78.678589)  // Northeast corner of Toronto
    );
    
    // Apply the bounds to bias the search results to the Toronto area
    autocomplete.setBounds(torontoBounds);
    autocomplete.setOptions({ strictBounds: true });

    autocomplete.addListener('place_changed', function() {
        const place = autocomplete.getPlace();

        // Extracting details
        const name = place.formatted_address;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        const placeIdTemp = place.place_id;

        callBack(name, latitude, longitude, placeIdTemp)
    });
    // Add any additional event listeners or functionalities as needed
};

export const loadScript = (setLocationFunction) => {
    const script = document.createElement('script');
    script.id = 'google-maps-script';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
        if (window.google && window.google.maps && window.google.maps.places) {
            initAutocomplete(setLocationFunction);
        }
    };
    document.head.appendChild(script);
};