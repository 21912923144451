import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import { getItemsByCategory, getPopularCat } from '../actions'
import { Typography, Avatar, Grid, Box, CircularProgress, Button, Paper } from '@mui/material';
import { useStyles } from '../../../styles';
import ItemCard from '../../../components/ItemCard';
import ItemBanner from '../../../components/ItemBanner';
import { useUser } from '../../User/userContext';

const Category = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {id} = useParams()

    const [items, setItems] = useState([]);
    const [popular, setPopular] = useState([])
    const [category, setCategory] = useState()
    const [isLoading, setIsLoading] = useState(true);

    const { location } = useUser();

    useEffect(() => {
        if(location){
            const origin = (location.coords.latitude).toString() + ',' + (location.coords.longitude).toString()
            getItemsByCategory(origin, id).then(response =>{
                setItems(response)
                setCategory(response[0] ? response[0].cat_name : null)
                setIsLoading(false)
            })
            getPopularCat(origin, id).then(response =>{
                setPopular(response)
            })
        }
        else{
            getItemsByCategory(false, id).then(response =>{
                setItems(response)
                setCategory(response[0] ? response[0].cat_name : null)
                setIsLoading(false)
            })
            getPopularCat(false, id).then(response =>{
                setPopular(response)
            })
        }
    }, []);

    const handleClick = (id) => {
        navigate(`../item/${id}`)
    }
  
    return (
        <Grid container>
            <Grid item xs ={12} mt={1} mb ={1}>
                <Typography variant ="h1" align="center">
                    {category}
                </Typography >
            </Grid>
            <Grid container item  xs={12} sm = {12} md = {12} spacing={2} ml = {2}>
                <Grid item xs={6} sm={6} ms = {6}>
                    <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                        <Typography variant="contrast">Popular</Typography>
                    </Paper>
                </Grid>
                <Grid item xs = {11}>
                    <ItemBanner items={popular.slice(0,25)}/>
                </Grid>
                <Grid item xs={6} sm={6} ms = {6}>
                    <Paper className={classes.miniBanner} style={{borderRadius: '10'}}>
                        <Typography variant="contrast">Recently uploaded</Typography>
                    </Paper>
                </Grid>
                {isLoading ?
                    <Box sx={{display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress style={{ color: 'black' }}/>
                    </Box> 
                    :
                    <Grid item xs = {11}>
                        <ItemBanner items={items.sort((a, b) => new Date(b.dateEntered) - new Date(a.dateEntered)).slice(0,25)}/>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
  };
  
  export default Category;