import axios from '../../axios.js';
import { getCookie } from '../util/cookieHelper.js';

const updateCSRFToken = () => {
    // Fetch the new CSRF token from the cookie
    let csrftoken = getCookie('csrftoken');
    // Update the Axios default header with the new CSRF token
    axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
};

export const loginUser = async (loginData) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login`, loginData);
    updateCSRFToken();
    return response.data
};

export const logoutUser = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/logout`);
    return response.data
};

export const getUserInformation = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/userprofile/${id}`)
    return response.data
} 

export const editUserProfile = async (id,data) => {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/user/${id}/`, data)
    return response.data
}

export const getRentalRating = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/review/?rental_id=${id}`)
    return response.data
}

export const getUserProfileRating = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/userprofilereview/?rental_id=${id}`)
    return response.data
}

export const checkAuth = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/checkauth`)
    return response.data
}

export const createUserProfile= async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/createuser/`,data)
    return response.data
}

export const checkEmail = async (email) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/checkifuserexists/?username=${email}`)
    return response.data
}

export const sendVerificationCode = async (email) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/generatecode/?email=${email}`)
    return response.data
}

export const verifyCode = async (email, code) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/verifycode/?email=${email}&&code=${code}`)
    return response.data
}

export const getItemsByOwner = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itembyowner/${id}/`)
    return response.data
}

export const getItemsByRenter = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rent/?userProfile_id=${id}`)
    return response.data
}

export const getRentalsByOwner = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getrentalsforowner/${id}/`)
    return response.data
}

export const getRentalsByRenter  = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getrentalsforrenter/${id}/`)
    return response.data
}

export const getUserStore = async (userId) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/store/?owner_id=${userId}`)
    return response.data
}

export const getStoreInformation = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/store/${id}/`)
    return response.data
}

export const updateStore = async (id, data) => {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/store/${id}/`,data)
    return response.data
}

export const createStore = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/store/`,data)
    return response.data
}


export const getRental = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rent/${id}`)
    return response.data
}

export const getRentalbyToken = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rent/?payment_token=${id}`)
    return response.data
}

export const getMessages = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/message/?rent_id=${id}`)
    return response.data
}

export const sendMessage = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/message/`,data)
    return response.data
}

export const sendMessageNotification = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sendmessagenotification/`,data)
    return response.data
}

export const createUserProfileReview = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/userprofilereview/`,data)
    return response.data
}

export const createItemReview = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/review/`,data)
    return response.data
}

export const verifyGoogleCredentials = async (creds) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/verifygooglecredentials/?credential=${creds}`)
    updateCSRFToken();
    return response.data
}

export const verifyFacebookCredentials = async (creds) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/verifyfacebookcredentials/`, creds)
    updateCSRFToken();
    return response.data
}