import { Typography, Grid, Button, Box, Divider, IconButton, TextField, InputAdornment, Avatar, Menu, MenuItem } from "@mui/material";
import { useNavigate  } from 'react-router-dom'; 
import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from '../styles'; 
import { mainHeadingColor } from "../theme";
import { useUser } from '../features/User/userContext';
import { getLocation } from "./Header";
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Footer() {

    const navigate = useNavigate()

    useEffect(() => {
    }, []);


    return (
        <footer>
                <Grid container mt={7} style = {{backgroundColor: mainHeadingColor, height: '100%', width:'100%' }}>
                    <Grid container item xs={12} sm = {12} md ={4} lg={4} mt={1}>
                        <Grid item xs={3}>
                        <Button style={{ color: 'white' }} onClick={()=>{}}>Help</Button>
                        </Grid>
                        <Grid item xs={3}>
                        <a 
                            href="/api/sitemap.xml" 
                            style={{
                                color: 'white', 
                                textDecoration: 'none', 
                                padding: '10px 20px', 
                                // backgroundColor: '#007bff', 
                                border: 'none', 
                                borderRadius: '4px', 
                                display: 'inline-block',
                                textAlign: 'center',
                                cursor: 'pointer'
                            }}
                            >
                            SiteMap
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
        </footer>
    )
}
