import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../userContext';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, CircularProgress, IconButton,InputAdornment, Paper, Button, TextField } from "@mui/material";
import { getMessages } from '../actions'
import ItemBanner from '../../../components/ItemBanner'
import WelcomeBanner from '../../../components/WelcomeBanner';
import SendIcon from '@mui/icons-material/Send';
import { useStyles } from '../../../styles';
import { theme } from '../../../theme';
import { sendMessage, sendMessageNotification } from '../actions';

export default function Messages(props){
  const { user, isAuthenticated } = useUser();
  const classes = useStyles();
  const navigate = useNavigate()
  const messageContainerRef  = useRef(null);

  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [messageText, setMessageText] = useState('')
  
  const createMessage = (content) => {
    
    const obj = {
        "rent_id": props.rentId,
        "sender_id": user.user.id,
        "receiver_id": user.user.id === props.rentalInformation.item.userProfile.id ? props.rentalInformation.userProfile.id : props.rentalInformation.item.userProfile.id,
        "content": content
    }
    sendMessage(obj).then(response => {
      setMessages([...messages, response])
      setMessageText('')
      sendMessageNotification({"messageId": response.id})
    })
  }


  useEffect(() => {
      getMessages(props.rentId).then(response => {
        setMessages(response)
        setIsLoading(false)
      })
  }, [props.rent])

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [messages])


  return (
    <>{isLoading ?
              <Box sx={{display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress style={{ color: 'black' }}/>
              </Box> 
                :
      <Grid container xs={10} sm = {10} md = {10} className = {classes.purpleBorder}>
         <style>
        {`
          .${classes.messageContainer}::-webkit-scrollbar {
            width: 10px;
          }

          .${classes.messageContainer}::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          .${classes.messageContainer}::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
          }

          .${classes.messageContainer}::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
        <Grid container className={classes.messageContainer} item mt={1} ref={messageContainerRef}>
            
            <Grid item xs = {12}>
              {messages.map(msg => (
                
                <div
                key={msg.id}
                style={{
                  display: 'flex',
                  justifyContent: msg.sender.id === user.user.id ? 'flex-end' : 'flex-start',
                  padding: '4px',
                }}
              >
                <Paper
                  key = {msg.id}
                  sx={{
                    maxWidth: 300,
                    padding: '10px 20px',
                    borderRadius: msg.content.length > 100 ? 10 : 20,
                    backgroundColor: '#f0f0f0', // Light grey background
                    border: '1px solid #e0e0e0', // Slightly darker border for definition
                    display: 'inline-block',
                    wordWrap: 'break-word', // Ensures text wraps to avoid overflow
                    whiteSpace: 'pre-wrap', 
                  }}
                  elevation={1} // Adjust the elevation for shadow depth
                >
                  {msg.content}
                </Paper>
              </div>
              ))}
            </Grid>
          </Grid>
            <Grid item xs={12} sm = {12} md = {12} container style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Grid item xs = {12}>
                <TextField 
                  fullWidth 
                  placeholder="Message"
                  onChange={(e)=> setMessageText(e.target.value)}
                  value = {messageText}
                  InputProps={{
                        style: {borderRadius: 10, backgroundColor:'#B3BAFF'}, // Adjust the value as needed
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick = {() => {createMessage(messageText)}}>
                                    <SendIcon/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
              </Grid>
        </Grid>
    </Grid>
    }
    </>
  );
};