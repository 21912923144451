export const calculateTotalDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the time difference in milliseconds
    const timeDifference = end - start;

    // Calculate the difference in days, hours, and minutes
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Add 1 to include the start date as a selected day
    const selectedDays = daysDifference;
    return selectedDays
}
