export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const getRentalStatus = (rh) => {
    if(rh.canceled){
        return {id:0, label: 'canceled', color:'#f55549'}
    }
    else if(rh.approved){
        if(rh.confirmed){
            return {id:1, label: 'approved and confirmed', color:'#5b8c61'}
        }
        else{
            return {id: 2, label: 'needs renter confirmation', color:'#d9c61a'}
        }

    }
    else if(rh.approved === false || rh.pickupDate < new Date().toISOString().split('T')[0]){
        return {id:3, label:'declined', color:'#f55549'}
    }
    else{
        return {id:4, label:'Pending Owner Approval', color:'#d9c61a'}
    }
}

export const getRentalAddress = (itemData) => {
    const useStore = itemData.useDefaultStoreLocation
    return useStore ? itemData.store.addressName : itemData.addressName
}