import React from "react";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from './Header'
import Footer from './Footer'

export default function Base() {

    useEffect(() => {

    }, [])

    return (
        <>
                <Header/>
            {
                <Outlet/> 
            }
                <Footer/>
        </>
    )
}
