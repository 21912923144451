import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from './features/User/userContext';
import { useEffect, useState } from 'react';
import { checkAuth } from './features/User/actions';
import { CircularProgress } from '@mui/material';


const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  // const { user, isAuthenticated, logout } = useUser();

  // console.log(user)

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    checkAuth().then(response => {
      if(response.user){
        setIsAuthenticated(true)
      }
      else{
        setIsAuthenticated(false)
      }
      setLoading(false)
    }).catch(response=>{
      setIsAuthenticated(false)
      setLoading(false)
    })
  },[])

  if(!loading){
    if (!isAuthenticated) {
      return <Navigate to="/base/login" state={{ from: location }} replace />;
    }

    return children;
  }
  else{
    return <CircularProgress/>
  }
}

export default ProtectedRoute;