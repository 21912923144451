import React from "react";
import { useNavigate  } from 'react-router-dom'; 
import { useEffect } from "react";
import { Button, Grid, Typography, Card, CardContent, CardMedia, Divider } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mainHeadingColor } from "../theme";
import shopNoImage from '../assets/shopNoImage.png'

export default function StoreCard({store, index}) {

    const navigate = useNavigate();

    const handleClick = () =>{
        navigate(`../base/storeinformation/${store.id}`, { state: { store } })
    }

    useEffect(() => {
        
    }, [store])


    return (
        <>

        <Grid item xs={12} sm={12} md={12} ml={index === 0 ? 0 : 2} style={{borderRadius: '20'}}>   
            <Card>
                <CardContent>
                    <div style={{ cursor: 'pointer' }} onClick = {() => {handleClick()}}>
                        <Grid container style = {{ height: '330px'}}>
                            <Grid item xs={12} sm = {12} md = {12} style= {{height: '70px'}}>
                                <Typography variant ="itemCardHeader" align="left" title={store.name}>
                                    {store.name.length > 90 ? `${store.name.substring(0, 87)}...` : store.name}
                                </Typography >
                            </Grid>
                            <Grid item xs={12} sm = {12} md = {12} style={{ height: '220px', overflow: 'hidden' }}>
                                <CardMedia
                                    component="img"
                                    image={store.image ? store.image : shopNoImage }
                                    title={store.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm ={12} md = {12} mt = {2}>
                                <Divider/>
                            </Grid>
                            <Grid container mt= {0.5}>
                                <Grid item xs={12}>
                                    <Typography variant ="smallDescriptor" align="left" title={store.addressName}>
                                        {store.addressName}
                                    </Typography >
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    </>)
}
