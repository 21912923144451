import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, Button, Grid, Typography, Divider, CircularProgress, CardMedia, TextField } from '@mui/material';
import { createItemReview, createUserProfileReview, getRentalRating, getUserProfileRating } from '../features/User/actions';
import Rating from '@mui/material/Rating';

export const ReviewDisplay = (props) =>{

    const [isRatingLoading, setIsRatingLoading] = useState(true)
    const [review, setReview] = useState()
    const [newReview, setNewReview] = useState()

    useEffect(() => {
        if(props.isOwner){
            getUserProfileRating(props.id).then(response => {
                setReview(response[0])
                setIsRatingLoading(false)
            })
        }
        else{
            getRentalRating(props.id).then(response=> {
                setReview(response[0])
                setIsRatingLoading(false)

            })
        }
    }, [props.id, props.isOwner])

    useEffect(() => {

    }, [review])

    const handleSubmit = () => {
        const obj = {...newReview}
        obj['rental_id'] = parseInt(props.id,10)
        if(props.isOwner){
            createUserProfileReview(obj).then(response => {
                setReview(response)
            })
        }
        else{
            createItemReview(obj).then(response=>{
                setReview(response)
            })
        }
    }

    return(
        <>
            {isRatingLoading ?
              <CircularProgress style={{ color: 'black' }}/>
            :
                <>
                <Grid contianer xs ={12}>
                    {props.isOwner ? 
                        
                            <Grid item xs = {12}>
                                { review ? 'You have reviewed this user' : 'Please review the Renter for future owners'}
                            </Grid>
                        :
                            <Grid item xs = {12}>
                                { review ? 'You have reviewed this item' : 'Please review your experience for future renters'}
                            </Grid>
                    }
                            <Grid item xs = {12} mt = {1}>
                             <Rating 
                                value={review ? review.numberOfStars: newReview}
                                disabled = {review}
                                onChange={(event, newValue) => {

                                    setNewReview({...newReview, numberOfStars:newValue});
                                }}
                             />
                            </Grid>
                            <Grid item xs = {12} mt ={1}>
                                {review ?
                                    <Typography>{review.reviewText}</Typography> 
                                : 
                                    <TextField multiline rows={3} fullWidth placeholder='Review' onChange={(e)=>{setNewReview({...newReview, reviewText:e.target.value})}}/>}
                            </Grid>
                            <Grid container item xs = {12} mt ={1} justifyContent="center">
                            {review ? '':
                                <Button variant='contained' onClick = {()=>{handleSubmit()}}>Submit</Button>}
                            </Grid>
                        </Grid>
                </>
            }
        </>
    )

}
