export const formatDateDB = (dt) => {
    return dt.toISOString()
}

export const formatDateNoTime = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate;
  };

  export const dateOptions = (date_string) =>{
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    return options
  }

  export const parseLocalDate = (dateString) => {
    // Split the date string into components (assuming 'YYYY-MM-DD' format)
    const [year, month, day] = dateString.split('-');
    // Create a new Date in local time
    return new Date(year, month - 1, day);
}