import axios from '../../axios.js'

export const createItem = async (itemData) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/item/`, itemData);
    return response.data
};

export const getItem = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/item/${id}`);
    return response.data
}

export const updateItem = async (id, itemData) => {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/item/${id}/`,itemData)
    return response.data
}

export const createImage  = async (imageData) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/image/`,imageData)
}

export const deleteImage = async (imageId) => {
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/image/${imageId}`)
}

export const getImages = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/image/?item_id=${id}`)
    return response.data
}

export const createRent = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rent/`,data)
    return response.data
}

export const updateRent = async (id, data) => {
    const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/rent/${id}/`,data)
    return response
}

export const getRentHistory = async (id, date) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rentdays/?item_id=${id}&dropoffDate__gt=${date}&confirmed=true`)
    return response.data
}

export const getItemsHomePage = async (location) => {
    let response = {}
    if(location){
        response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itemsforhome/?origin=${location}`)
    }
    else{
        response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itemsforhome/`)
    }
    return response.data
}

export const getCategories = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/category/`)
    return response.data
}

export const getItemsByCategory = async (origin, category_id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/itemsforcategory/?origin=${origin}&&category=${category_id}`)
    return response.data
}

export const getPopularCat = async (origin, category_id) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/popularcat/?origin=${origin}&&category=${category_id}`)
    return response.data
}

export const getPopularSearch = async (searchText) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/popularsearch/?search=${searchText}`)
    return response.data
}

export const getItemsBySearch = async (obj) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/itembysearch/`, obj)
    return response.data
}

export const getDistancePersonToItem = async (origin, destination) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getdistancematrix/?origin=${origin}&destination=${destination}`)
    return response.data
}

export const generatePaymentToken = async (rentId) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/generatepaymenttoken/`, {rentId})
    return response.data
}

export const deleteItem = async (item) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/deleteitem/`, {item})
    return response.data
}